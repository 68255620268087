<template>
    <div style="position: relative">
        <v-overlay
                :value="loadingItems"
                absolute
                opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-form lazy-validation ref="bundlesForm">
            <v-data-table
                :disable-pagination="disablePagination"
                :items-per-page="itemsPerPage"
                :hide-default-footer="disablePagination"
                :headers="headers"
                :items="Bundles"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                disable-sort
                hide-default-header
                item-key="index"
                single-select
            >
                <template v-slot:header="{ props: { headers } }">
                    <tr>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-7-pct">{{ headers[1].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left width-40-pct">
                            <v-row no-gutters>
                                <v-col cols="6">{{ $t('message.product') }}</v-col>
                                <v-col cols="6" class="pl-1">{{ $t('message.spec') }}</v-col>
                            </v-row>
                        </th>
                        <th class="grey lighten-3 pa-1 text-left">{{ headers[3].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left" :class="$vuetify.breakpoint.xl ? 'width-15-pct' : 'width-10-pct'">{{ headers[4].text }}</th>
                        <th class="grey lighten-3 pa-1 text-left" :class="$vuetify.breakpoint.xl ? 'width-15-pct' : ''">{{ headers[5].text }}</th>
                        <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[6].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.lineNumber="{ item }">
                    <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
                </template>
                <template v-slot:item.Stock.bundleno="{ item }">
                    <v-text-field
                        :id="item.index + 'bundleno'"
                        :rules="[...validationRules.plainTextWithNumberAndSymbols,...validationRules.required]"
                        :value="item.Stock.bundleno"
                        autocomplete="off"
                        class="custom force-text-left pl-0 pr-0  bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        @change="updateBundleRowHandle( item.index, 'Stock.bundleno',$event)"
                        v-if="item.Stock.stockstate_id != 1"
                    />
                    <div class="font-weight-bold mt-2 text-no-wrap" v-else>{{ item.Stock.bundleno }}</div>
                </template>
                <template v-slot:item.Stock.productgroup_id="{item}">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="d-flex flex-row align-center">
                                <div style="width: 100px" class="font-16px">{{ $t('message.product') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'product'"
                                    :items=allProductGroups
                                    :placeholder="item.Stock.productgroup_id ? '' : $t('message.required')"
                                    :rules="[...validationRules.required]"
                                    :value="item.Stock.productgroup_id"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="ProductGroup.title"
                                    item-value="ProductGroup.id"
                                    style="width: 150px"
                                    @change="updateBundleRowHandle( item.index, 'Stock.productgroup_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 100px" class="font-16px">{{ $t('message.species') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'species'"
                                    :items="item.Options.species"
                                    :placeholder="item.Options.species.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                    :rules="[...validationRules.required]"
                                    :value="item.Stock.species_id"
                                    clearable
                                    class="custom pa-1 bg-lightgrey"
                                    dense
                                    hide-details="auto"
                                    item-value="Species.id"
                                    item-text="Species.title"
                                    return-object
                                    style="width: 150px"
                                    @change="updateBundleRowHandle( item.index, 'Stock.species_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 100px" class="font-16px">{{ $t('message.certification') + ":" }}</div>
                                <v-select
                                    :id="item.index + 'certification'"
                                    :items="allCertifications"
                                    :placeholder="$t('message.select')"
                                    :value="item.Stock.certification_id"
                                    class="text-right custom pa-1 bg-lightgrey"
                                    dense
                                    hide-details
                                    item-value="Certification.id"
                                    item-text="Certification.title"
                                    style="width: 150px"
                                    @change="updateBundleRowHandle( item.index, 'Stock.certification_id',$event)"
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <div class="d-flex flex-row align-center">
                                <div style="width: 50px" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'grade'"
                                    :items="item.Options.grades"
                                    :placeholder="item.Options.grades.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                    :value="item.Stock.grade_id"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Grade.title"
                                    item-value="Grade.id"
                                    return-object
                                    @change="updateBundleRowHandle( item.index, 'Stock.grade_id',$event)"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 50px" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                                <v-combobox
                                    :id="item.index + 'spec'"
                                    :items="item.Options.specs"
                                    :placeholder="item.Options.specs.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                    :value="item.Spec.title"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Spec.id"
                                    item-text="Spec.title"
                                    return-object
                                    @focus="currentRowIndex = item.index"
                                    @change="updateBundleRowHandle( item.index, 'Stock.spec_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center" v-if="item.Stock.productgroup_id != 15">
                                <div style="width: 50px" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                                <v-select
                                    :id="item.index + 'mc'"
                                    :items="allMoistureContent"
                                    :value="item.Stock.mc_id"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Mc.title"
                                    item-value="Mc.id"
                                    @focus="currentRowIndex = item.index"
                                    @change="updateBundleRowHandle( item.index, 'Stock.mc_id',$event)"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.Stock.size_id="{ item }">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px" class="font-16px">{{ $t('message.thickness') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'thickness'"
                            :items="filteredThickness"
                            :placeholder="$t('message.select')"
                            :value="item.Stock.thickness_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Thickness.title"
                            item-value="Thickness.id"
                            @focus="currentRowIndex = item.index"
                            @click="filterThickness(StockOrder__ms_id)"
                            @change="updateBundleRowHandle( item.index, 'Stock.thickness_id',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px" class="font-16px">{{ $t('message.width') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'width'"
                            :placeholder="$t('message.freeText')"
                            :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                            :value="item.Stock.width"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @change="updateBundleRowHandle( item.index, 'Stock.width',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 75px" class="font-16px">{{ $t('message.length') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'length'"
                            :placeholder="$t('message.freeText')"
                            :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                            :value="item.Stock.length"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @change="updateBundleRowHandle( item.index, 'Stock.length',$event)"
                        />
                    </div>
                </template>
                <template v-slot:item.Stock.volume="{ item }">
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.pcs') + ":" }}</div>
                        <v-text-field
                                :id="item.index + 'piece'"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Stock.piece"
                                class="custom pa-1 bg-lightgrey"
                                dense
                                hide-details="auto"
                                @change="updateBundleRowHandle( item.index, 'Stock.piece',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.qty') + ":" }}</div>
                        <div class="d-flex" :class="$vuetify.breakpoint.xl ? 'flex-row align-center' : 'flex-column'">
                            <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="item.Stock.volume"
                                    autocomplete="off"
                                    class="custom pl-0 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    reverse
                                    @change="updateBundleRowHandle( item.index, 'Stock.volume',$event)"
                                    v-if="item.Stock.stockstate_id != 1"
                            />
                            <div :style="$vuetify.breakpoint.xl ? 'width: 100px;' : ''" :class="$vuetify.breakpoint.xl ? 'ml-2' : ''">
                                <v-select
                                        :id="item.index + 'uofm'"
                                        :items="filteredUnits"
                                        :rules="[...validationRules.required]"
                                        :value="item.Stock.soldmeasurement_id"
                                        class="text-right custom pa-1 mb-0 bg-lightgrey"
                                        dense
                                        hide-details="auto"
                                        item-value="Measurement.id"
                                        item-text="Measurement.title"
                                        reverse
                                        @change="updateBundleRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-slot:item.Stock.costprice="{ item }">
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 110px" class="font-16px">{{ $t('message.buyingPrice') + ":" }}</div>
                        <template v-if="item.Stock.stockstate_id != 1">
                            <v-text-field
                                    :id="item.index + 'costprice'"
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="item.Stock.costprice"
                                    autocomplete="off"
                                    class="custom pl-0 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateBundleRowHandle( item.index, 'Stock.costprice',$event)"
                            />
                        </template>
                        <template v-else>
                            <div class="mt-2 text-no-wrap">{{ item.Stock.costprice }}</div>
                        </template>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 110px" class="font-16px">{{ $t('message.importDuty') + ":" }}</div>
                        <v-text-field
                                :id="item.index + 'importduty'"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Stock.importduty"
                                autocomplete="off"
                                class="custom pl-0 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateBundleRowHandle( item.index, 'Stock.importduty',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 110px" class="font-16px">{{ $t('message.storageCost') + ":" }}</div>
                        <v-text-field
                                :id="item.index + 'storageCost'"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Stock.warehousecost"
                                autocomplete="off"
                                class="custom pl-0 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateBundleRowHandle( item.index, 'Stock.warehousecost',$event)"
                        />
                    </div>
                    <div class="d-flex flex-row align-center pb-2">
                        <div style="min-width: 110px" class="font-16px">{{ $t('message.freightCost') + ":" }}</div>
                        <v-text-field
                                :id="item.index + 'freightCost'"
                                :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                :value="item.Stock.forwardingcharge"
                                autocomplete="off"
                                class="custom pl-0 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateBundleRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                                v-if="item.Stock.stockstate_id != 1"
                        />
                    </div>
                </template>
                <template v-slot:item.Stock.id="{ item }">
                    <div class="d-flex flex-column">
                        <template v-if="[3,2].includes(item.Stock.stockstate_id)">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="deleteBundle(item.index, item.Stock.id, item.Stock.bundleno)" v-on="on">
                                        <v-icon color="grey">delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                        </template>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="copyBundle(item.index)" v-on="on" :key="'ci_' + item.Stock.id">
                                    <v-icon color="grey" class="mdi mdi-content-duplicate"></v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.copyThisItem') }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-3 pb-0 px-0 d-flex align-end">
                            <v-btn
                                color="success"
                                small
                                @click="addBundleRow(statePrefix)"
                            >{{ Bundles.length == 0 ? $t('message.addBundle') : $t('message.addMore') }}
                            </v-btn>
                            <v-btn
                                :loading="resettingStockOrderBundles"
                                class="ml-2"
                                color="default"
                                small
                                v-if="updateMode == false"
                                @click="resetBundles()"
                            ><v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                            </v-btn>
                            <v-btn small class="ml-2" :href="stockUploadTemplateFile">
                                <v-icon color="black darken-1">mdi-download</v-icon> {{ $t('message.getExcelFileSample') }}
                            </v-btn>
                            <v-btn
                                class="ml-2"
                                color="blue lighten-4"
                                small
                                @click="uploadByExcelDialog = true"
                            ><v-icon color="black darken-1">mdi-upload</v-icon> {{ $t('message.uploadBundlesByExcelFile') }}
                            </v-btn>
                        </div>
                        <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                            <v-row class="justify-end">
                                <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                    <div class="text-right font-sm font-weight-black">{{ $t('message.currency') }}</div>
                                    <v-select
                                        :items="allCurrencies"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Currency.code"
                                        item-value="Currency.id"
                                        reverse
                                        v-model="StockOrder__currency_id"
                                    ></v-select>
                                </v-col>
                                <v-col cols="2" lg="2" md="2" xs="6" class="pb-0">
                                    <div class="text-right font-sm font-weight-black">
                                        {{ stockOrderCurrency.Currency.code + ":" + officeCurrency.Currency.code + " " + $t('message.exRate') }}
                                    </div>
                                    <v-text-field
                                        :value="StockOrder__currencyrate"
                                        autocomplete="off"
                                        class="custom pt-0 pb-2 mt-0 bg-lightgrey"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        type="number"
                                        @change="StockOrder__currencyrate = $event"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
        <BundleExcelUploadV2
            :stock-order-id="StockOrder__id"
            :dialog.sync="uploadByExcelDialog"
            :update-mode="updateMode"
            @dialog-closed="uploadByExcelDialogClosed"
            @upload-done="bundlesUploaded"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {generateDimension, generateSpec, log, numberFormat} from "Helpers/helpers";
import {api} from '../../api';

const BundleExcelUploadV2 = () => import("Components/Appic/BundleExcelUploadV2");
export default {
    name: "BundleListV2",
    components: { BundleExcelUploadV2 },
    props: ['updateMode','loadOptions','validate'],
    data() {
        return {
            currentRowIndex: 0,
            disablePagination: true,
            filteredThickness: [],
            filteredUnits: [],
            itemsPerPage: -1,
            loadingItems: false,
            resettingStockOrderBundles: false,
            uploadByExcelDialog: false,
            warehouseVars: {
                importDuties: {},
                storageCost: 0
            }
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stockorder',{
            StockOrder__currency_id: 'StockOrder.currency_id',
            StockOrder__currencyrate: 'StockOrder.currencyrate',
            StockOrder__currencyratedate: 'StockOrder.currencyratedate',
            StockOrder__forwardingcost: 'StockOrder.forwardingcost',
            StockOrder__id: 'StockOrder.id',
            StockOrder__lmeasurement_id: 'StockOrder.lmeasurement_id',
            StockOrder__ms_id: 'StockOrder.ms_id',
            StockOrder__office_id: 'StockOrder.office_id',
            StockOrder__purchaseorder_id: 'StockOrder.purchaseorder_id',
            StockOrder__smeasurement_id: 'StockOrder.smeasurement_id',
            StockOrder__vmeasurement_id: 'StockOrder.vmeasurement_id',
            StockOrder__warehousecost: 'StockOrder.warehousecost',
            StockOrder__wmeasurement_id: 'StockOrder.wmeasurement_id',
            StockOrder__Contract__productorigin_id: 'StockOrder.Contract.productorigin_id',
        }, 'statePrefix'),
        ...mapFields('stockorder',{
            newStockOrderSaved: 'newStockOrderSaved',
            newStockOrderUnsaved: 'newStockOrderUnsaved',
            updatedStockOrderSaved: 'updatedStockOrderSaved',
            updatedStockOrderUnsaved: 'updatedStockOrderUnsaved',
        }),
        ...mapMultiRowFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('size',{
            allSizes: 'allSizes',
            findSizeById: 'findSizeById'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmQty',
            'uofmDimensions',
            'validationRules'
        ]),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.no'),
                    value: 'lineNumber'
                },
                {
                    id: 2,
                    text: this.$t('message.bundle'),
                    value: 'Stock.bundleno'
                },
                {
                    id: 3,
                    text: this.$t('message.product'),
                    value: 'Stock.productgroup_id'
                },
                {
                    id: 4,
                    text: this.$t('message.size'),
                    value: 'Stock.size_id'
                },
                {
                    id: 5,
                    text: this.$t('message.quantity'),
                    value: 'Stock.volume'
                },
                {
                    id: 6,
                    text: this.$t('message.pricing'),
                    value: 'Stock.costprice'
                },
                {
                    id: 7,
                    text: this.$t('message.action'),
                    value: 'Stock.id'
                }
            ]
        },
        officeCurrency () {
            const office = this.offices.find(o => o.Office.id == this.StockOrder__office_id)
            if(office) {
                return this.allCurrencies.find(c => c.Currency.id == office.Office.currency_id)
            } else {
                return this.allCurrencies.find(c => c.Currency.id == 1)
            }
        },
        stockOrderCurrency () {
			let currency = null
            if(this.StockOrder__currency_id) {
                currency = this.allCurrencies.find(c => c.Currency.id == this.StockOrder__currency_id)
            } else {
				currency = this.allCurrencies.find(c => c.Currency.id == 1)
            }
			return currency
        },
        stockUploadTemplateFile() {
            let ms = 'metric'
            if(this.StockOrder__ms_id == 2){
                ms = 'imperial'
            }
            if(this.StockOrder__purchaseorder_id) {
                return process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template/' + ms + '/' + this.StockOrder__purchaseorder_id
            } else {
                return process.env.VUE_APP_API_BASE_URL + '/stockorders/stock-upload-template/' + ms
            }
        }
    },
    methods: {
        ...mapActions('certification',{
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('stock',{
            addBundleRow: 'addBundleRow',
            deleteStockOrderBundle: 'deleteStockOrderBundle',
            getBundlesByStockOrder: 'getBundlesByStockOrder',
            resetStockOrderBundles: 'resetStockOrderBundles',
            updateBundleStatus: 'updateBundleStatus'
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        ...mapMutations('stock',{
            deleteStockOrderBundleRow: 'deleteStockOrderBundleRow',
            copyStockOrderBundleRow: 'copyStockOrderBundleRow'
        }),
        bundlesUploaded () {
            this.uploadByExcelDialog = false;
        },
        calculateStockCosts() {
            this.Bundles.forEach((bundle, index) => {
                //calculate storage cost
                // log(bundle['Stock']['costprice'])
                const storageCost = bundle['Stock']['costprice'] * this.warehouseVars.storageCost
                this.$store.commit('stock/updateBundles', {
                    index: index,
                    field: 'Stock.warehousecost',
                    prefix: this.statePrefix,
                    value: storageCost
                })

                //calculate import duty
                let importDuty = 0
                //check product
                if(bundle['Stock']['productgroup_id'] != null) {
					const productKey = bundle['Stock']['productgroup_id'].toString()
					if (this.warehouseVars.importDuties[productKey]) {
						//check origin
						const originKey = this.StockOrder__Contract__productorigin_id
						if (this.warehouseVars.importDuties[productKey][originKey]) {
							importDuty = bundle['Stock']['costprice'] * this.warehouseVars.importDuties[productKey][originKey]
							this.$store.commit('stock/updateBundles', {
								index: index,
								field: 'Stock.importduty',
								prefix: this.statePrefix,
								value: importDuty
							})
						} else {
							//check if no origin is set
							if (this.warehouseVars.importDuties[productKey]['noOrigin']) {
								importDuty = bundle['Stock']['costprice'] * this.warehouseVars.importDuties[productKey]['noOrigin']
								this.$store.commit('stock/updateBundles', {
									index: index,
									field: 'Stock.importduty',
									prefix: this.statePrefix,
									value: importDuty
								})
							}
						}
					}

					this.$store.commit('stock/updateBundles', {
						index: index,
						field: 'Stock.importduty',
						prefix: this.statePrefix,
						value: importDuty
					})
				}
            })
        },
        copyBundle(itemIndex) {
            const promise = new Promise((resolve, reject) => {
                try {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.copyStockOrderBundleRow(payload)
                    resolve('ok')
                } catch(err) {
                    reject(err)
                }
            });
            this.loadingItems = true
            promise
                .then(() => {
                    this.loadingItems = false
                })
                .catch(() => {
                    this.loadingItems = false
                })
        },
        async deleteBundle( itemIndex, itemId, bundleNo ) {
            if (await this.$root.$confirm(this.$t('message.delete') + " " + bundleNo, this.$t('message.confirmations.continueDeleteBundleAction'), {color: 'red'})) {
                const promise = new Promise((resolve, reject) => {
                    let payload = {
                        index: itemIndex,
                        prefix: this.statePrefix
                    }
                    this.deleteStockOrderBundleRow(payload)
                    resolve('ok')
                });
                this.loadingItems = true
                promise
                        .then(() => {
                            if(itemId != null){
                                this.deleteStockOrderBundle({
                                    stockOrderId: this.StockOrder__id,
                                    itemId: itemId
                                })
                                .then(()=>{
                                    let payload = {
                                        prefix: this.statePrefix,
                                        stockOrderId: this.StockOrder__id
                                    }
                                    this.getBundlesByStockOrder(payload)
                                        .then( () => {
                                            this.updatedStockOrderUnsaved = false
                                            this.loadingItems = false
                                        })
                                        .catch(() => {
                                            this.updatedStockOrderUnsaved = false
                                            this.loadingItems = false
                                        })
                                })
                                .catch(() => {
                                    this.updatedStockOrderUnsaved = false
                                    this.loadingItems = false
                                })
                            } else {
                                this.loadingItems = false
                            }
                        })
                        .catch(() => {
                            this.updatedStockOrderUnsaved = false
                            this.loadingItems = false
                        })
            }
        },
        filterGradesByProduct(productId){
            let filteredGrades = this.allGrades.filter(grade => {
                return grade.Grade.productgroups.includes(productId)
            })
            if(filteredGrades.length > 0) {
                return filteredGrades
            } else {
                return []
            }
        },
        filterSpeciesByProduct(productId){
            let filteredSpecies = this.allSpecies.filter(species => {
                return species.Species.productgroups.includes(productId)
            })
            if (filteredSpecies.length > 0) {
                return filteredSpecies
            } else {
                return []
            }
        },
        filterSpecsByProduct(productId){
            let filteredSpecs = this.allCleanedSpecs.filter(spec => {
                return spec.Spec.productgroups.includes(productId)
            })
            if(filteredSpecs.length > 0) {
                return filteredSpecs
            } else {
                return []
            }
        },
        filterThickness(ms) {
            let various = this.allThickness.find(t => {
                return t.Thickness.ms == ms && t.Thickness.title == 'various'
            })
            let thicknesses = this.allThickness.filter(t => {
                return t.Thickness.ms == ms && t.Thickness.title != 'various'
            })
            thicknesses.sort((a,b) => {
                let strA = a + ''
                let strB = b + ''
                return strA.localeCompare(strB, undefined, { numeric: true })
            })
            if(various){
                this.filteredThickness = [various,...thicknesses]
            } else {
                this.filteredThickness = thicknesses
            }
        },
        getUiDescription(item) {
            let uiDescription = []

            const species = this.allSpecies.find(s => s.Species.id == item.Stock.species_id)
            if(species) uiDescription.push(species.Species.title)

            if(item.Stock.description != null) {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                if (product && item.Stock.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
            } else {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                if (product) uiDescription.push(product.ProductGroup.title)
            }

            if(item.Stock.description != '') uiDescription.push(item.Stock.description)

            // const grade = this.allGrades.find(g => g.Grade.id == item.Stock.grade_id)
            // if(grade) uiDescription.push(grade.Grade.title)

            let certification = this.allCertifications.find(c => c.Certification.id == item.Stock.certification_id)
            if(certification && item.Stock.description != null && item.Stock.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
            if(certification && item.Stock.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

            const mc = this.allMoistureContent.find(mc => mc.Mc.id == item.Stock.mc_id)
            if(mc && item.Stock.description == null) uiDescription.push(mc.Mc.title)

            uiDescription = uiDescription.filter(Boolean).join(", ")
            return uiDescription
        },
        getSize(sizeId) {
            let size = this.allSizes.find(s => s.Size.id == sizeId)
            if(size != null){
                return size.Size.title
            } else {
                return null
            }
        },
        getUnit(uomId) {
            let uofm = this.uofmQty.find(m => m.Measurement.id == uomId)
            if(uofm)  return uofm.Measurement.title
            return ''
        },
        getWarehouseVars() {
            return new Promise((resolve, reject) => {
                api
                    .get('/warehouse-vars/' + this.StockOrder__office_id + '/all')
                    .then((response) => {
                        if(response.data.status == 'success'){
                            const vars = response.data.data
                            if(vars){
                                this.warehouseVars.importDuties = vars.import_duties
                                this.warehouseVars.storageCost = vars.storage_cost
                            }
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        async resetBundles () {
            if (await this.$root.$confirm(this.$t('message.resetStockArrivalBundles'), this.$t('message.confirmations.continueStockArrivalBundlesReset'), {color: 'orange'})) {
                this.resettingStockOrderBundles = true
                this.resetStockOrderBundles(this.statePrefix).then(() => {
                    this.resettingStockOrderBundles = false
                })
            }
        },
        setItemDescription(idx) {
            if(idx != null) {
                let product = null
                let spec = null
                let mc = null

                let productGroupId = this.Bundles[idx].Stock.productgroup_id
                let specId = this.Bundles[idx].Stock.spec_id
                let mcId = this.Bundles[idx].Stock.mc_id

                let productObj = this.allProductGroups.find(pg => pg.ProductGroup?.id == productGroupId)
                let specObj = this.allCleanedSpecs.find(s => s.Spec.id == specId)
                if(specId == 'NEW'){
                    specObj = { Spec: this.Bundles[idx]['Spec'] }
                }
                if(typeof specObj === 'undefined'){
                    specObj = { Spec: this.Bundles[idx]['Spec'] }
                }
                let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == mcId)

                if(productObj) product = productObj?.ProductGroup?.title
                if(specObj && specObj?.Spec?.title != 'na') spec = specObj?.Spec?.title
                if(mcObj) mc = mcObj?.Mc?.title

                let description = generateSpec(product, spec, mc)
                this.Bundles[idx].Stock.description = description
            }
        },
        updateBundleRowHandle( idx, field, value ) {
            let payload = null
            switch(field) {
                case 'Stock.spec_id':
                    if(value) {
                        if(value?.Spec?.id) { //value is an object
                            payload = {
                            index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            }
                            // assign Spec property in NcrItem
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: value?.Spec?.id
                            })
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value?.Spec?.title
                            })

                            //update Stock.mc_id if empty and Spec.mc_id is not - VJC @ 2021-08-06
                            let specObj = this.allSpecs.find(s => s.Spec.id == value?.Spec?.id)
                            if(specObj && specObj?.Spec.mc_id != null) {
                                this.Bundles[idx]['Stock']['mc_id'] = specObj?.Spec?.mc_id
                            }
                        } else {
                            //value is a string - new spec
                            payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: 'NEW'
                            }
                            // assign Spec property in Stock
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: 'NEW'
                            })
                            this.$store.commit('stock/updateBundles', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: value
                            })
                        }
                    } else {
                        //if empty
                        payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: null
                        }
                        // assign Spec property in NcrItem
                        this.$store.commit('stock/updateBundles', {
                            index: idx,
                            field: 'Spec.id',
                            prefix: this.statePrefix,
                            value: null
                        })
                        this.$store.commit('stock/updateBundles', {
                            index: idx,
                            field: 'Spec.title',
                            prefix: this.statePrefix,
                            value: null
                        })
                    }
                    break;
                case 'Stock.productgroup_id':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //filter species
                    const species = this.filterSpeciesByProduct(value)
                    const speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species
                    }
                    this.$store.commit('stock/updateBundles', speciesPayload)

                    //filter grades
                    const grades = this.filterGradesByProduct(value)
                    const gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades
                    }
                    this.$store.commit('stock/updateBundles', gradesPayload)

                    //filter specs
                    const specs = this.filterSpecsByProduct(value)
                    const specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs
                    }
                    this.$store.commit('stock/updateBundles', specsPayload)
                    break;
                case 'Stock.soldmeasurement_id':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    //assign sold measurement property in Stock
                    let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                    if(measurement != null) {
                        this.$store.commit('stock/updateBundles', {
                            index: idx,
                            field: 'Soldmeasurement.id',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                        })
                        this.$store.commit('stock/updateBundles', {
                            index: idx,
                            field: 'Soldmeasurement.title',
                            prefix: this.statePrefix,
                            value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                        })
                    }
                    break;
                case 'Stock.species_id':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    }
                    // assign Species property in Stock
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Species.id',
                        prefix: this.statePrefix,
                        value: value?.Species?.id
                    })
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Species.title',
                        prefix: this.statePrefix,
                        value: value?.Species?.title
                    })
                    break;
                case 'Stock.grade_id':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    }
                    // assign Grade property in Stock
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Grade.id',
                        prefix: this.statePrefix,
                        value: value?.Grade?.id
                    })
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Grade.title',
                        prefix: this.statePrefix,
                        value: value?.Grade?.title
                    })
                    break;
                case 'Stock.':
                    if(typeof value === 'undefined') value = null
                    break;
                case 'Stock.fsc':
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    const certification = this.allCertifications.find(c => c.Certification.title == value)
                    if(certification){
                        this.$store.commit('stock/updateBundles', {
                            index: idx,
                            field: 'Stock.certification_id',
                            prefix: this.statePrefix,
                            value: certification.Certification.id
                        })
                    }
                    break;
                case 'Stock.thickness_id':
                case 'Stock.width':
                case 'Stock.length':
                    if(typeof value === 'undefined') value = null

                    //update size_text
                    let thicknessId = this.Bundles[idx].Stock.thickness_id
                    let width = this.Bundles[idx].Stock.width
                    let length = this.Bundles[idx].Stock.length
                    if(field == 'Stock.thickness_id'){
                        thicknessId = value
                    }
                    if(field == 'Stock.width'){
                        width = value
                    }
                    if(field == 'Stock.length'){
                        length = value
                    }

                    const thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                    const thickness = thicknessObj?.Thickness.title
                    let unit = thicknessObj?.Thickness.suffix
                    if(unit == null){
                        unit = ''
                    }

                    const sizeText = generateDimension(thickness,width,length,unit)

                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Stock.size',
                        prefix: this.statePrefix,
                        value: sizeText
                    })

                    //update Size object
                    //check if size exists
                    let sizeId = null
                    let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                    if(sizeObj){
                        sizeId = sizeObj?.Size.id
                    } else {
                        if (sizeText.length > 0) {
                            sizeId = 'NEW' //new size object
                        }
                    }
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Size.id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Size.title',
                        prefix: this.statePrefix,
                        value: sizeText
                    })
                    this.$store.commit('stock/updateBundles', {
                        index: idx,
                        field: 'Stock.size_id',
                        prefix: this.statePrefix,
                        value: sizeId
                    })
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
                    // log(payload)
                    break;
                default:
                    if(typeof value === 'undefined') value = null
                    payload = {
                        index: idx,
                        field: field,
                        prefix: this.statePrefix,
                        value: value,
                    }
            }
            const promise = new Promise((resolve, reject) => {
                try {
                    this.$store.commit('stock/updateBundles', payload)
                    resolve('ok')
                } catch(err) {
                    reject(err)
                }
            });
            promise
                .then(() => {
                    this.calculateStockCosts()
                    if(['Stock.productgroup_id','Stock.spec_id','Stock.grade_id','Stock.mc_id'].includes(field)){
                        this.setItemDescription(idx)
                    }
                    console.log('Bundle updated...')
                })
        },
        uploadByExcelDialogClosed() {
            this.uploadByExcelDialog = false;
        }
    },
    watch: {
        StockOrder__ms_id(value){
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == value || u.Measurement.system == null
            })
            this.filterThickness(value)
        },
        loadOptions(value){
            if(value){
                this.Bundles.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('stock/updateBundles', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('stock/updateBundles', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('stock/updateBundles', specsPayload)
                })
                this.$emit('options-reloaded')
            }
        },
        validate(value) {
            if(value){
                let valid = this.$refs.bundlesForm.validate()
                if(valid) {
                    this.$emit('items-valid')
                } else {
                    this.$emit('items-invalid')
                }
            }
        }
    },
    created () {
        if(this.Bundles.length > 20){
            this.disablePagination = false
            this.itemsPerPage = 10
        }
        if(this.allSpecies.length == 0) this.getAllSpecies()
        if(this.allGrades.length == 0) this.getAllGrades()
        if(this.allCertifications.length == 0) this.getAllCertifications()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
        if(this.allProductGroups.length == 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
        if(this.allSizes.length == 0) this.getAllSizes()
        if(this.allThickness.length == 0) this.getAllThickness()
        this.getWarehouseVars()
    },
    mounted() {
        this.$refs.bundlesForm.resetValidation()
        this.$store.watch(
            function (state) {
                return state.appic.stock.update.Bundles
            },
            () => {
                this.updatedStockOrderUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.stock.current.Bundles
            },
            () => {
                this.newStockOrderUnsaved = true
            },
            {deep: true}
        )
        this.$nextTick(function () {
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == this.StockOrder__ms_id || u.Measurement.system == null
            })
            this.filterThickness(this.StockOrder__ms_id)
            if(this.updateMode) {
                this.Bundles.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('stock/updateBundles', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('stock/updateBundles', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('stock/updateBundles', specsPayload)
                })
                this.$emit('options-reloaded')
            } else {
                if(this.Bundles.length > 0){
                    this.$emit('reloading-options')
                    this.Bundles.forEach((item, idx) => {
                        //filter species
                        let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                        let speciesPayload = {
                            index: idx,
                            field: 'Options.species',
                            prefix: this.statePrefix,
                            value: species,
                        }
                        this.$store.commit('stock/updateBundles', speciesPayload)

                        //filter grades
                        let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                        let gradesPayload = {
                            index: idx,
                            field: 'Options.grades',
                            prefix: this.statePrefix,
                            value: grades,
                        }
                        this.$store.commit('stock/updateBundles', gradesPayload)

                        //filter specs
                        let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                        let specsPayload = {
                            index: idx,
                            field: 'Options.specs',
                            prefix: this.statePrefix,
                            value: specs,
                        }
                        this.$store.commit('stock/updateBundles', specsPayload)
                    })
                }
                this.$emit('options-reloaded')
            }
        })
    },
    updated() {
        this.filteredUnits = this.uofmQty.filter(u => {
            return u.Measurement.system == this.StockOrder__ms_id || u.Measurement.system == null
        })
        this.filterThickness(this.StockOrder__ms_id)
        this.$nextTick(function () {
            if (this.updateMode) {
                this.Bundles.forEach((item, idx) => {
                    //filter species
                    const species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    const speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('stock/updateBundles', speciesPayload)

                    //filter grades
                    const grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    const gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('stock/updateBundles', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('stock/updateBundles', specsPayload)
                })
                this.$emit('options-reloaded')
            }
        })
    }
}
</script>

<style>
width-12-pct {
    width: 12%;
}
width-18-pct {
    width: 18%;
}
width-7-pct {
    width: 7%;
}
width-25-pct {
    width: 25%;
}
width-20-pct {
    width: 20%;
}
.width-40-pct {
    width: 40%;
}
.width-45-pct {
    width: 45%;
}
.bg-lightgrey {
    background-color: #F5F5F5;
}
.col-lg-4-5 {
    flex: 0 0 37.5;
    max-width: 37.5% !important;
}
.col-lg-7-5 {
    flex: 0 0 62.5;
    max-width: 62.5% !important;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.custom.v-select .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
</style>